// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-tsx": () => import("C:\\Repos\\prague-golf-card\\src\\pages\\contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-courses-tsx": () => import("C:\\Repos\\prague-golf-card\\src\\pages\\courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Repos\\prague-golf-card\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-tsx": () => import("C:\\Repos\\prague-golf-card\\src\\pages\\map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-pages-submitted-tsx": () => import("C:\\Repos\\prague-golf-card\\src\\pages\\submitted.tsx" /* webpackChunkName: "component---src-pages-submitted-tsx" */)
}

